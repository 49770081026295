import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";

import { Loader } from "~/components/loader";
import { DEFAULT_VALUES } from "./address.constants";
import { formatAddress } from "./address.utils";

const AddressContext = createContext({
  isLoading: [],
  setIsLoading: () => {},
});

export const AddressProvider = ({ children, ...props }) => {
  const [isLoading, setIsLoading] = useState([]);
  const [address, setAddress] = useState(DEFAULT_VALUES);
  const [userAddress, setUserAddress] = useState(DEFAULT_VALUES);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [verifiedAddress, setVerifiedAddress] = useState();
  const form = useForm();

  const setLoader = useCallback((values = []) => {
    setIsLoading([ ...isLoading, ...values ]);
  }, [isLoading, setIsLoading]);

  const removeLoader = useCallback((values = []) => {
    values.forEach(value => {
      isLoading.splice(isLoading.indexOf(value), 1);
    });
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const isLoader = useCallback(value => {
    return !!(isLoading.indexOf(value) >= 0);
  }, [isLoading]);

  useEffect(() => {
    setFormattedAddress(
      formatAddress(address),
    );
  }, [address, setFormattedAddress]);

  return (
    <AddressContext.Provider
      value={{
        isLoading,
        isLoader,
        setLoader,
        removeLoader,
        address,
        setAddress,
        verifiedAddress,
        setVerifiedAddress,
        userAddress,
        setUserAddress,
        formattedAddress,
        ...props,
        ...form
      }}
    >
      <Loader isLoading={isLoader("submitting")} />
      {children}
    </AddressContext.Provider>
  )
}

export const useAddress = () => useContext(AddressContext);
