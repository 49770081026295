const CATEGORIES = {
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  CLICK: 'click',
  PURCHASE: 'purchase',
  REMOVE_FROM_CART: 'remove_from_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
};

export const ANALYTICS_ACTIONS = {
  ADD_TO_CART: 'add to cart',
  CHANGE_FONT: 'change font',
  CHECKOUT: 'checkout',
  PERSONALIZE: 'personalize',
  CHANGE_QUANTITY: 'change quantity',
};

export const trackEvent = ({ category, action, label, value }) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', category, {
      'event_category': action,
      'event_label': label,
      'value': value,
    });
  }

  if (typeof window.heap !== 'undefined') {
    window.heap.track(`${category}:${action}${label ? `:${label}` : ''}${value ? `:${value}` : ''}`);
  }
}

export const trackProduct = (category, data) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', category, data);
  }
}

/**
 * Documentation:
 * https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
 */
export const track = {
  addToCart(product) {
    trackProduct(CATEGORIES.ADD_TO_CART, {
      items: [{
        ...product,
        category: 'Greeting & Note Cards',
        list_name: 'Search Results',
      }],
    });
  },
  checkout(products, coupon = "") {
    trackProduct(CATEGORIES.BEGIN_CHECKOUT, {
      coupon,
      items: products.map(p => ({
        ...p,
        category: 'Greeting & Note Cards',
        list_name: 'Search Results',
      })),
    });
  },
  purchase(products, metadata = {}) {
    trackProduct(CATEGORIES.PURCHASE, {

      /**
       * Optional:
       * - transaction_id,
       * - affiliation,
       */
      items: products.map(p => ({
        ...p,
        category: 'Greeting & Note Cards',
        list_name: 'Search Results',
      })),
      ...metadata,
    });
  },
  click(data) {
    trackEvent({
      category: CATEGORIES.CLICK,
      ...data,
    });
  },
  removeFromCart(product) {
    trackProduct(CATEGORIES.REMOVE_FROM_CART, {
      items: [{
        ...product,
        category: 'Greeting & Note Cards',
        list_name: 'Search Results',
      }],
    });
  },
  viewItem(product) {
    trackProduct(CATEGORIES.VIEW_ITEM, {
      items: [{
        ...product,
        category: 'Greeting & Note Cards',
        list_name: 'Search Results',
      }],
    });
  },
  viewItems(products) {},
  impression(data) {},
}
