import memoize from "lodash/memoize";
import { IS_CLIENT, CLOUDINARY_URL } from "~/constants";

export const getImageSrc = (fileName, options) => {
  const transforms = options ? `,${options}` : '';
  const dpr = IS_CLIENT ? window.devicePixelRatio : 2;
  const width = IS_CLIENT ? window.innerWidth : "auto";
  return fileName && `${CLOUDINARY_URL}f_auto,q_auto,w_${width},dpr_${dpr}${transforms}/${fileName}`
}

export const getIsLandscape = memoize((image = { width: 0, height: 0 }) => {
  return (image?.width > image?.height);
})
