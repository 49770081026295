import React from "react";

export const FlagUSA = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 32 32"
    width="24"
    height="24"
    viewBox="0 0 32 32"
  >
    <path
      d="M3 7v10h26v-2H17v-2h12v-2H17V9h12V7H3zm2 1a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm4 0a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm4 0a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm-6 3a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm4 0a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm4 0a1 1 0 1 1 0 2a1 1 0 0 1 0-2zM5 14a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm4 0a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm4 0a1 1 0 1 1 0 2a1 1 0 0 1 0-2zM3 19v2h26v-2H3zm0 4v2h26v-2H3z"
      fill="currentColor"
    />
  </svg>
);
