export const colors = {
  primary: "#373F73", // "#403B2B", // black - 3D467F, 272F66, 373F73
  secondary: "#888888",
  neutral: "#f2f2f2",
  neutralTwo: "#e2e2e2",
  accentOne: "#FFD9CD", // "#FFECAD", // beige - 7A8CFF
  accentTwo: "#7A8CFF", // purple
  accentThree: "#5662B3", // darker purple
  accentFour: "#B3A168", // gold
  white: "#FFFFFF",
  black: "#000000",
  success: "#84BF69",
  error: "#BF110C",

  // Brands
  producthunt: "#ca5e3b",
};

export const palette = {
  primary: {
    // light: '#757ce8',
    main: '#5662B3',
    // dark: '#002884',
    // contrastText: '#fff',
  },
  secondary: {
    // light: '#ff7961',
    main: '#888888',
    // dark: '#ba000d',
    // contrastText: '#000',
  },
  light: {
    main: '#ffffff',
    dark: '#e2e2e2',
    contrastText: '#373F73',
  },
}
