import React, { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  TruncateWrapper,
  TruncateToggle,
  TruncateCopy,
} from "./truncate.style";

export const Truncate = ({ preview, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleOpen = () => setIsVisible(!isVisible);

  return (
    <TruncateWrapper onClick={toggleOpen}>
      <TruncateCopy $isActive={isVisible}>
        {isVisible ? content : preview || content}
      </TruncateCopy>
      {!isVisible && (
        <TruncateToggle $isActive={isVisible}>
          <ArrowDropDownIcon />
        </TruncateToggle>
      )}
    </TruncateWrapper>
  );
}
