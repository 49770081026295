import axios from "axios";

export const createCheckoutSession = async (line_items = [], coupon = "") => {
  let response = { data: {} };
  const url = `${process.env.GATSBY_FIREBASE_FUNCTIONS_URL}/createCheckoutSession`;
  const data = {
    coupon,
    line_items,
    cancel_url: window.location.pathname,
  };
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    response = await axios.post(url, data, options);
  }
  catch (err) {
    console.error(err);
    return Promise.reject();
  }

  return Promise.resolve(response.data);
}

export const getCouponCode = async ({ couponName }) => {
  let response = { data: {} };
  const url = `${process.env.GATSBY_FIREBASE_FUNCTIONS_URL}/getCouponCode`;
  const data = { couponName };
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    response = await axios.post(url, data, options);
  }
  catch (err) {
    console.error(err.message);
    return Promise.reject();
  }

  return Promise.resolve(response.data);
};
