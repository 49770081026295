import styled, { css } from "styled-components";
import { Link } from "gatsby";

import { IS_MEDIUM, IS_SMALL, MEDIA_NO_TOUCH } from "~/utils/media";
import { Subheadline } from "~/components/typography";
import { Grid } from "~/components/grid";

export const NavigationBackground = styled.div`
  position: relative;
  z-index: 2;
  background-color: transparent;
  transition: background-color 150ms linear 350ms;
  transition-property: background-color, box-shadow;

  & > ${Grid} {
    position: relative;
    z-index: 2;
    padding-top: 1rem;
  }
`;

export const NavigationSticky = styled.div`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  width: 100%;
  background-color: transparent;
  transition: box-shadow 120ms ease-out 0s;
  transition-property: box-shadow, background-color, backdrop-filter;

  ${({ theme, hasScrolled }) => hasScrolled && css`
    background-color: rgba(255,255,255, 0.9);
    backdrop-filter: saturate(0.7) blur(8px);
    box-shadow: ${theme.custom.shadows.one};
  `};

  ${({ isOpen }) => isOpen && css`
    ${NavigationBackground} {
      background-color: rgba(255,255,255, 1);
      box-shadow: 0 1px 0 rgba(0,0,0, 0.08);
      transition-delay: 0s;
    }
  `}

  ${({ fullView }) => fullView && css`
    position: fixed;
  `};
`;

export const NavigationWrapper = styled.nav`
  position: relative;
  display: flex;
  align-items: stretch;

  ${IS_SMALL} {
    flex-direction: column;
  }
`;

export const NavigationControls = styled.div`
  display: flex;
  flex-grow: 1;

  ${IS_SMALL} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const NavigationList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;

  ${IS_SMALL} {
    justify-content: center;
  }
`;

export const NavigationActions = styled(NavigationList)`
  margin-left: auto;
`;

export const NavigationAction = styled.button`
  cursor: pointer;
  position: relative;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  background-color: transparent;
  border: transparent;
  appearance: none;

  ${IS_SMALL} {
    margin-left: 0;
  }
`;

export const NavigationFeature = styled(Link).attrs(() => ({
  "role": "img",
}))`
  cursor: pointer;
  position: relative;
  display: table-cell;
  width: 240px;
  color: ${({ theme }) => theme.colors.white};
  background-image: url(${({ src }) => src});
  background-position: right center;
  background-size: cover;
  border-radius: 1rem;
  border-bottom-right-radius: 0;
  border: none;

  ${Subheadline} {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1rem;
    text-shadow: ${({ theme }) => theme.custom.shadows.two};
  }

  ${MEDIA_NO_TOUCH} {
    &:hover {
      border: none;
    }
  }

  ${IS_SMALL} {
    display: block;
    width: 100%;
    height: 120px;
    margin-bottom: 2rem;
  }
`;

export const NavigationItem = styled.li`
  display: block;
  margin: 0;
`;

export const Badge = styled.div`
  ${({ theme }) => theme.typography.tiny};
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  min-width: 12px;
  min-height: 12px;
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentFour};
  border-radius: 12px;
  transform: translateY(-100%);
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 4px;

  ${IS_SMALL} {
    position: absolute;
    margin-top: -4px;
  }
`;

export const NavigationLink = styled(Link)`
  ${({ theme }) => theme.typography.bodyHeadline};
  box-sizing: border-box;
  display: block;
  height: 100%;
  margin: 0 1rem 4px;
  padding: 1rem 0;
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: 1.4px;
  color: ${({ theme }) => theme.colors.primary};
  border-bottom: 4px solid transparent;
  transition: border-color 0.3s linear 0s;

  ${({ $isActive }) => $isActive && css`
    border-bottom-color: ${({ theme }) => theme.colors.primary};
  `}

  ${MEDIA_NO_TOUCH} {
    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const NavigationDropdown = styled.div`
  overflow-x: auto;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgba(255,255,255, 1);
  box-shadow: ${({ theme }) => theme.custom.shadows.three};
  transition: transfrom 300ms cubic-bezier(.75,0,.33,1) 0s;
  transition-property: transform, opacity, box-shadow;
  transform: translateY(-100%);
  opacity: 0;

  ${({ isOpen }) => isOpen && css`
    pointer-events: auto;
    transform: translateY(0);
    opacity: 1.0;
  `}

  ${NavigationItem} {
    padding-bottom: 0.25rem;
  }
`;

export const NavigationDrawer = styled(NavigationDropdown)`
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 50vw;
  max-width: 420px;
  transform: translateX(100%);

  ${({ isOpen }) => isOpen && css`
    pointer-events: auto;
    transform: translateX(0);
    opacity: 1.0;
  `}

  ${IS_MEDIUM} {
    width: 60vw;
  }

  ${IS_SMALL} {
    width: 100vw;
    max-width: none;
  }
`;

export const NavigationDropdownContent = styled.div`
  padding: 3rem 0;

  img {
    width: 240px;
    height: auto;
  }

  ${NavigationItem} {
    a,
    a:visited,
    a:link {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom: 0px solid transparent;
      transition: border 0.12s linear;
      transition-property: border-color, border-width, color, font-weight, letter-spacing;
    }
  }

  ${NavigationList} {
    display: block;
    margin: auto;
    columns: 200px 4;
  }

  ${NavigationFeature} + ${NavigationList} {
    margin-left: 4rem;
    columns: 150px 4;
    text-align: right;
  }

  ${NavigationWrapper} {
    display: table;
  }

  ${MEDIA_NO_TOUCH} {
    ${NavigationItem} a:hover {
      color: ${({ theme }) => theme.colors.accentTwo};
      font-weight: ${({ theme }) => theme.typography.bodyHeadline};
      border-bottom: 2px solid ${({ theme }) => theme.colors.accentTwo};
    }
  }

  ${IS_SMALL} {
    ${NavigationWrapper} {
      display: flex;
    }

    ${NavigationList} {
      display: block;
      margin: auto;
      columns: 160px 2;
    }

    ${NavigationFeature} + ${NavigationList} {
      margin-left: auto;
      text-align: left;
      columns: 160px 2;
    }
  }
`;

export const NavigationOverlay = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.accentTwo};
  transition: opacity 0.12s linear 0s;
  opacity: 0;

  ${({ isOpen }) => isOpen && css`
    pointer-events: auto;
    opacity: 0.4;
  `};
`;
