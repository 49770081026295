import React from "react";
import { ThemeProvider } from '@mui/material/styles';

import theme from "~/themes";
import { Navigation } from "~/components/navigation";

import "~/styles/reset.css";
import "~/styles/fonts.css";
import "~/styles/global.css";

export const Main = ({ fullView, children }) => (
  <ThemeProvider theme={theme}>
    <main>
      <Navigation {...{ fullView }} />
      {children}
    </main>
  </ThemeProvider>
);
