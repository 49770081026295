import isEqual from "lodash/isEqual";
import { useStore, actions } from "~/store";
import { normalizeCartItem } from "~/utils/products";
import { formatCurrency } from "~/utils/currency";

const isCartItemEqual = (item, itemToCompare) => (
  item.id === itemToCompare.id &&
  isEqual(item.metadata, itemToCompare.metadata)
);

const getUpdatedSubTotal = (items = []) => {
  const grandTotal = items.reduce((total, { price: { value = 0 }, quantity }) => {
    return total + Number(value);
  }, 0);

  return {
    subTotal: {
      display: formatCurrency(grandTotal),
      value: grandTotal,
    },
  }
};

export const useCart = () => {
  const { state: { cart } } = useStore();
  return cart;
};

export const useCartCount = () => {
  const { items = [] } = useCart();
  return items.reduce((total, { quantity = 0 }) => {
    return total + Number(quantity);
  }, 0);
};

export const useFindCartItem = () => {
  const cart = useCart();
  return ({ id, metadata }) => cart.items.find(item => {
    return isCartItemEqual(item, { id, metadata });
  });
};

export const useAddItemToCart = () => {
  const { state: { cart }, dispatch } = useStore();
  const findCartItem = useFindCartItem();

  return shopifyItem => {
    const item = normalizeCartItem(shopifyItem);
    const previousItem = findCartItem(item);
    const quantity = previousItem ? previousItem.quantity + 1 : 1;
    let updatedCartItems = [];

    if (previousItem) {
      cart.items.forEach(item => {
        if (isCartItemEqual(item, previousItem)) {
          item.quantity = quantity;
          item.price = {
            display: formatCurrency(item.unitPrice * quantity),
            value: item.unitPrice * quantity,
          };
        }
      });
      updatedCartItems = cart.items;
    } else {
      updatedCartItems = [
        ...cart.items,
        {
          ...item,
          quantity,
          addTime: new Date().getTime(),
        },
      ];
    }

    updatedCartItems = updatedCartItems
      .sort((a, b) => b.addTime - a.addTime);

    dispatch(actions.updateCartTotals(
      getUpdatedSubTotal(updatedCartItems),
    ));
    dispatch(actions.updateCartItems(updatedCartItems));
  }
};

export const useUpdateItemQuantity = () => {
  const { state: { cart }, dispatch } = useStore();
  const findCartItem = useFindCartItem();

  return (item, quantity) => {
    const cartItem = findCartItem(item);
    let updatedCart = [];

    if (!cartItem) {
      return;
    }

    if (quantity <= 0) {
      cart.items = cart.items.filter(item =>
        !isCartItemEqual(item, cartItem));
    }
    else {
      cart.items.forEach(item => {
        if (isCartItemEqual(item, cartItem)) {
          item.price = {
            display: formatCurrency(item.unitPrice * quantity),
            value: Number(item.unitPrice * quantity),
          }
          item.quantity = quantity;
        }
      });
    }

    updatedCart = cart.items;

    dispatch(actions.updateCartTotals(
      getUpdatedSubTotal(updatedCart),
    ));
    dispatch(actions.updateCartItems(updatedCart));
  };
};

export const useClearCart = () => {
  const { dispatch } = useStore();
  return () => {
    dispatch(actions.updateCartTotals(
      getUpdatedSubTotal([]),
    ));
    dispatch(actions.updateCartItems([]));
  }
}
