import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  CardWrapper,
  CardArt,
  CardFront,
  CardBack,
} from "./card.style";

export const Card = ({
  width,
  front,
  back,
  rotate,
  alt,
  isLandscape,
}) => {
  const cardRef = useRef();

  useEffect(() => {
    const el = cardRef.current;
    el?.addEventListener('contextmenu', evt => evt.preventDefault());
    return () => {
      el?.removeEventListener('contextmenu', evt => evt.preventDefault());
    }
  });

  return (
    <CardWrapper ref={cardRef} {...{ alt, width, rotate }}>
      <CardFront>
        <CardArt src={front} />
      </CardFront>
      {back && (
        <CardBack imagePath={back} />
      )}
    </CardWrapper>
  );
}
Card.defaultProps = {
  width: 100,
  front: "https://res.cloudinary.com/thegoodsnail/image/upload/v1618191688/believe_you_can-front_vgkdkx.jpg",
};
Card.propTypes = {
  width: PropTypes.string,
  front: PropTypes.string,
  back: PropTypes.string,
  rotate: PropTypes.number,
  alt: PropTypes.string,
  isLandscape: PropTypes.bool,
};
