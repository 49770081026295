import styled, { css } from "styled-components";

import { MEDIA_NO_TOUCH } from "~/utils/media";

export const AddressTag = styled.address`
  flex-grow: 1;
  margin-bottom: 0;
  text-align: center;
  font-style: normal;
`;

export const AddressList = styled.div`
  padding: 1rem 0 2rem;
`;

const ActiveAddressOptionStyles = css`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.accentOne};
  &:before {
    border: 4px solid ${({ theme }) => theme.colors.primary};
  }
`;
export const AddressOption = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  border-radius: 1rem;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 1rem;
  }

  & + & {
    margin-top: 1rem;
  }

  ${({ $isActive }) =>
    $isActive &&
    ActiveAddressOptionStyles}

  ${MEDIA_NO_TOUCH} {
    &:hover {
      ${ActiveAddressOptionStyles}
    }
  }
`;
