import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useMount } from 'react-use';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { ModalFooter } from "~/components/modal";
import { ArrowIcon } from "~/components/icons";
import {
  Form,
  FormRow,
  FormCell,
} from "~/components/form";

import { COUNTRIES, DEFAULT_VALUES, STATES } from "./address.constants";
import { verify, provinceLookup } from "./address.requests";
import { useAddress } from "./address.provider";
import { formatAddress } from "./address.utils";
import { InputZip } from "./input";

const isValidAddress = (address = {}) => (
  address?.street1
  && address?.city
  && address?.state
  && address?.zip
);

export const AddressForm = () => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    setAddress,
    setUserAddress,
    verifiedAddress,
    setVerifiedAddress,
    isLoader,
    setLoader,
    removeLoader,
    onSubmit,
    formState: {
      errors,
    },
  } = useAddress();

  const [provinces, setProvinces] = useState(STATES);
  const [countryCode, setCountryCode] = useState("");
  const fetchProvinces = useCallback(async (countryCode = "US") => {
    try {
      const foundProvinces = await provinceLookup(countryCode);
      return setProvinces(foundProvinces);
    }
    catch (err) {
      console.error('Fetching provinces ', err);
    }
  }, [setProvinces]);

  const verifyAddress = useCallback(async (data) => {
    if (data.country !== "US") {
      onSubmit(formatAddress(data));
      return reset(DEFAULT_VALUES);
    }

    setLoader(["submitting"]);
    const verifyResponse = await verify(data) || {};
    const verifiedAddress = {
      ...verifyResponse,
      name: data?.name,
    };

    removeLoader(["submitting"]);
    setVerifiedAddress(verifiedAddress);
    setAddress(verifiedAddress || data);
    setUserAddress(data);

    if (!isValidAddress(verifiedAddress)) {
      onSubmit(formatAddress(data));
      reset(DEFAULT_VALUES);
    }
  }, [
    reset,
    setLoader,
    removeLoader,
    setVerifiedAddress,
    setAddress,
    setUserAddress,
    onSubmit,
  ]);

  const onCountryChange = useCallback(({ target }) => {
    setCountryCode(target.value);
  }, [setCountryCode]);

  const onSkip = () => {
    onSubmit();
    reset(DEFAULT_VALUES);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "50vh",
      },
    },
  };

  useEffect(() => {
    fetchProvinces(countryCode);
  }, [fetchProvinces, countryCode])

  useMount(fetchProvinces, []);

  return !verifiedAddress ? (
    <Form
      action="#"
      onSubmit={handleSubmit(verifyAddress)}
      disabled={isLoader("submitting")}
    >
      <FormRow>
        <FormCell>
          <TextField
            label="Recipient Name"
            variant="outlined"
            error={!!(errors.name)}
            autoComplete="name"
            inputProps={{
              ...register("name", {
                pattern: /^[A-Za-z\s-.]+$/i,
                required: true
              }),
            }}
          />
        </FormCell>
      </FormRow>

      <FormRow>
        <FormCell>
          <FormControl fullWidth>
            <InputLabel id="country-select-label">Country</InputLabel>
            <Select
              labelId="country-select-label"
              id="country-select"
              value={countryCode}
              label="Country"
              name="country"
              onChange={onCountryChange}
              inputProps={{
                ...register("country", {
                  required: true
                }),
              }}
            >
              {COUNTRIES.map(({ code, name }) => (
                <MenuItem key={code} value={code}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormCell>
      </FormRow>

      {countryCode && (
        <>
          <FormRow>
            <FormCell>
              <TextField
                label="Address Line One"
                variant="outlined"
                error={!!(errors.street1)}
                inputProps={{
                  ...register("street1", { required: true }),
                  autoComplete: "address-line1",
                }}
              />
            </FormCell>
          </FormRow>

          <FormRow>
            <FormCell>
              <TextField
                label="Address Line Two"
                variant="outlined"
                error={!!(errors.street2)}
                inputProps={{
                  ...register("street2", { required: false }),
                  autoComplete: "address-line2",
                }}
              />
            </FormCell>
          </FormRow>

          <FormRow>
            <FormCell>
              <InputZip />
            </FormCell>
            <FormCell>
              <Controller
                control={control}
                name="city"
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <TextField
                    label="City"
                    variant="outlined"
                    autoComplete="address-level2"
                    disabled={isLoader("city")}
                    error={!!(errors.city)}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputLabelProps={{
                      shrink: !!value,
                    }}
                  />
                )}
              />
            </FormCell>

            {provinces.length > 0 && (
              <FormCell>
                <FormControl sx={{ minWidth: 96, maxWidth: 128 }}>
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Controller
                    control={control}
                    name="state"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        label="State"
                        labelId="state-select-label"
                        id="state-select"
                        autoComplete="address-level1"
                        defaultValue=""
                        disabled={isLoader("state")}
                        MenuProps={MenuProps}
                        value={value || ""}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {provinces.map(({ abbreviation: abbr, name }) => {
                          const value = abbr || name;
                          return <MenuItem key={value} value={value}>{value}</MenuItem>;
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </FormCell>
            )}
          </FormRow>
        </>
      )}

      <ModalFooter>
        <Stack
          direction={{ xs: 'column-reverse', sm: 'row' }}
          spacing={2}
          style={{ width: "100%" }}
        >
          <Button
            variant="outlined"
            onClick={onSkip}
            fullWidth
          >
            I'll send it myself
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isLoader("submitting")}
            loading={isLoader("submitting")}
            endIcon={<ArrowIcon />}
            fullWidth
          >
            Verify Address
          </LoadingButton>
        </Stack>
      </ModalFooter>
    </Form>
  ) : null;
};
