import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';

export const CartDiscountInput = ({ discount, addDiscount }) => {
  const [message, setMessage] = useState('');
  const [value, setValue] = useState('');
  const onChange = ({ target }) => setValue(target.value.toUpperCase());
  const onEnter = ({ keyCode }) => (keyCode === 13) && requestDiscount();
  const requestDiscount = async () => {
    setValue("");
    const response = await addDiscount(value);
    setMessage(response.message);
  };

  return (
    <FormControl
      sx={{ marginBottom: "0.5rem" }}
      fullWidth
    >
      <InputLabel htmlFor="outlined-adornment-password" size="small">Discount</InputLabel>
      <OutlinedInput
        fullWidth
        id="outlined-adornment-password"
        label="Discount"
        variant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        inputProps={{
          onKeyDown: onEnter,
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              disabled={!value}
              onClick={requestDiscount}
              edge="end"
            >
              <AddIcon color={value ? "primary" : "secondary"} />
            </IconButton>
          </InputAdornment>
        }
      />
      {message && <FormHelperText error>{message}</FormHelperText>}
    </FormControl>
  );
}
