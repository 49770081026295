import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { navigate } from "gatsby"

import { IS_CLIENT } from "~/constants";
import { useCartCount, useScroll } from "~/hooks";
import { SearchIcon, CartIcon } from "~/components/icons";
import { PromoBanner } from "~/components/promo-banner";
import { Cart } from "~/components/cart";
import { Logo } from "~/components/logo";
import { Grid } from "~/components/grid";

import { NavigationCards } from "./navigation-cards.component";
import { NavigationDesigners } from "./navigation-designers.component";

import {
  Badge,
  LogoLink,
  NavigationSticky,
  NavigationWrapper,
  NavigationControls,
  NavigationList,
  NavigationActions,
  NavigationAction,
  NavigationItem,
  NavigationLink,
  NavigationDrawer,
  NavigationDropdown,
  NavigationBackground,
  NavigationOverlay,
} from "./navigation.style";

const DRAWER_CONTENT = {
  CART: "cart",
};

export const Navigation = ({ fullView }) => {
  const drawerRef = useRef();
  const cartCount = useCartCount();
  const [prevCartCount, setPrevCartCount] = useState(cartCount);
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const toggleCart = () => setIsOpen(DRAWER_CONTENT.CART);
  const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
  const isThankYouPage = IS_CLIENT && window.location.pathname.startsWith("/thank-you")
  const onSearch = () => navigate("/search");

  const drawer = useMemo(() => {
    return [
      {
        label: DRAWER_CONTENT.CART,
        drawer: <Cart onClose={closeMenu} />,
      },
    ];
  }, [closeMenu]);

  const content = useMemo(() => [
    {
      label: "cards",
      dropdown: <NavigationCards closeMenu={closeMenu} />,
    },
    {
      label: "designers",
      dropdown: <NavigationDesigners closeMenu={closeMenu} />,
    },
    {
      label: "our story",
      to: "story",
    },
  ], [closeMenu]);

  const navigationItems = useMemo(() => {
    return content
      .map(({ label, dropdown, to }) => {
        const isActive = isOpen === label;
        const onClick = (event) => {
          if (dropdown) {
            event.preventDefault();
          }
          setIsOpen(!isActive && label);
        };

        return (
          <NavigationItem key={label}>
            <NavigationLink
              to={`/${to || label}`}
              onClick={onClick}
              $isActive={isActive}
            >
              {label}
            </NavigationLink>
          </NavigationItem>
        );
      });
  }, [content, isOpen]);

  const navigationContent = useMemo(() => {
    return content
      .filter(l => !!(l.dropdown))
      .map(({ label, dropdown }) => (
        <NavigationDropdown
          key={label}
          isOpen={isOpen === label}
        >
          {dropdown}
        </NavigationDropdown>
      ));
  }, [content, isOpen]);

  const drawerContent = useMemo(() => {
    return drawer
      .map(({ label, drawer }) => (
        <NavigationDrawer
          key={label}
          ref={drawerRef}
          isOpen={isOpen === label}
        >
          {drawer}
        </NavigationDrawer>
      ));
  }, [drawer, isOpen]);

  useScroll(() => {
    if (window.scrollY > 0 && !hasScrolled) {
      setHasScrolled(true);
    }
    if (window.scrollY === 0) {
      setHasScrolled(false);
    }
  });

  useEffect(() => {
    if (cartCount > 0 && cartCount !== prevCartCount) {
      toggleCart();
      setPrevCartCount(cartCount);
    }
  }, [
    cartCount,
    prevCartCount,
    setPrevCartCount,
  ]);

  return (
    <>
      <NavigationSticky {...{ hasScrolled, isOpen, fullView }}>
        <NavigationBackground>
          <PromoBanner />
          <Grid>
            <NavigationWrapper>
              <LogoLink to="/">
                <Logo />
              </LogoLink>
              <NavigationControls>
                <NavigationList>
                  {navigationItems}
                </NavigationList>
                <NavigationActions>
                  <NavigationAction onClick={onSearch}>
                    <SearchIcon />
                  </NavigationAction>
                  {!isThankYouPage &&(
                    <NavigationAction name="cards" onClick={toggleCart}>
                      {cartCount > 0 && <Badge>{cartCount}</Badge>}
                      <CartIcon />
                    </NavigationAction>
                  )}
                </NavigationActions>
              </NavigationControls>
            </NavigationWrapper>
          </Grid>
        </NavigationBackground>
        {navigationContent}
      </NavigationSticky>

      {drawerContent}

      <NavigationOverlay
        {...{ isOpen }}
        onPointerUp={closeMenu}
      />
    </>
  );
};
