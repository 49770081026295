import styled from "styled-components";

export const PromoBannerWrapper = styled.div`
  ${({ theme }) => theme.typography.small};
  padding: 1.5rem 2rem 0.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, $variant }) =>
    theme.colors[$variant] || theme.colors.accentTwo};
`;
