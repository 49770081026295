import styled from "styled-components";

import { IS_SMALL, MEDIA_NO_TOUCH } from "~/utils/media";
import { Grid } from "~/components/grid";
import { Body, BodyHeadline } from "~/components/typography";

export const FooterWrapper = styled.footer`
  padding: 4rem 0;
  text-align: right;
  color: ${({ theme }) => theme.colors.neutralTwo};
  background-color: ${({ theme }) =>
    theme.colors.accentFour};

  a {
    color: ${({ theme }) => theme.colors.white};
  }

  ${Grid} {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  ${Grid} + ${Grid} {
    margin-top: 3rem;
    justify-content: space-between;
    align-items: center;
  }

  ${Body} + ${BodyHeadline} {
    margin-top: 2rem;
  }

  ${MEDIA_NO_TOUCH} {
    a:hover {
      color: ${({ theme }) => theme.colors.secondary};
      text-decoration: underline;
    }
  }

  ${IS_SMALL} {
    text-align: center;

    ${Grid} {
      flex-direction: column;
    }
  }
`;

export const FooterColumn = styled.div`
  margin-left: 4rem;

  ${BodyHeadline} {
    margin-bottom: 1rem;
  }

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(2) {
    margin-left: auto;
  }

  ${IS_SMALL} {
    &,
    &:first-child {
      margin: auto;
    }

    & + & {
      margin-top: 2rem;
    }

    ${BodyHeadline} {
      margin-bottom: 0;
    }

    ${Body} {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const FooterBlock = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
