import React, { useCallback } from "react";
import TextField from '@mui/material/TextField';

import { useAddress } from "~/components/form/address";
import { cityStateLookup } from "./../address.requests";

export const InputZip = () => {
  const {
    register,
    isLoader,
    setLoader,
    removeLoader,
    setValue,
    getValues,
    trigger,
    formState: {
      errors,
    },
  } = useAddress();

  const { ref, onChange, onBlur } = register("zip", {
    minLength: 5,
    required: true,
  });

  const onBlurZip = useCallback(async ({ target }) => {
    const values = getValues();

    if (!values.city && values.country === "US") {
      setLoader([ "city", "state" ]);

      const results = await cityStateLookup(target.value);

      if (results) {
        setValue("city", results.city, { shouldDirty: true });
        setValue("state", results.state, { shouldDirty: true });
      }

      removeLoader([ "city", "state" ]);
    }
  }, [setLoader, removeLoader, getValues, setValue]);

  return (
    <TextField
      inputRef={ref}
      label="Zip/Postal"
      disabled={isLoader("zip")}
      error={!!(errors.zip)}
      autoComplete="postal-code"
      onChange={(e) => {
        onChange(e);
        setValue("zip", e.target.value, { shouldDirty: true });
      }}
      onBlur={async (e) => {
        onBlur(e);
        onBlurZip(e);
        trigger("zip");
      }}
    />
  );
}
