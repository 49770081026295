import styled, { css } from "styled-components";

import { IS_SMALL } from "~/utils/media";
import { HeroHeadline } from "~/components/typography";

export const HeroTitleWrapper = styled.div`
  position: relative;
  padding: 12rem 0 4rem;
  color: ${({ theme }) => theme.colors.accentTwo};
  background-color: ${({ theme, bgColor }) =>
    theme.colors[bgColor || "accentOne"]};

  ${HeroHeadline} {
    position: relative;
    z-index: 1;
  }

  ${({ align }) => align && css`
    text-align: ${align};
  `}

  ${({ imagePath }) => imagePath && css`
    margin-bottom: 4rem;
    padding-top: 24rem;
    padding-bottom: 4rem;
    color: ${({ theme }) => theme.colors.accentOne};
    text-shadow: ${({ theme }) => theme.shadows[2]};
    background-image: url(${imagePath});
    background-position: center;
    background-size: cover;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0.25) 100%
      );
    }
  `}

  ${IS_SMALL} {
    text-align: center;
  }
`;

export const HeroTitleImage = styled.figure`
  display: block;
  margin: -4rem auto 4rem;
  width: 50%;
  height: ${({ height }) => height}px;
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  ${IS_SMALL} {
    width: 90vw;
  }
`;
