import React from "react";
import PropTypes from "prop-types";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ClearIcon from '@mui/icons-material/Clear';

import { formatCurrency } from "~/utils/currency";
import { Small, SmallHeadline } from "~/components/typography";
import {
  CartFooterLineItem,
  CouponTag,
  CouponLabel
} from "./../cart.style";

export const CartDiscount = ({ discount, clearDiscount }) => {
  if (!discount.amount_off && !discount.percent_off) {
    return null;
  }

  const display = discount.amount_off
    ? `-${formatCurrency(discount.amount_off / 100)}`
    : discount.percent_off && `-${discount.percent_off}%`

  return (
    <CartFooterLineItem $discount>
      <SmallHeadline as="span">Discount</SmallHeadline>
      <CouponTag>
        <LocalOfferIcon sx={{ fontSize: "0.75rem" }} />
        <CouponLabel>{discount.name}</CouponLabel>
        <ClearIcon
          sx={{
            cursor: "pointer",
            fontSize: "0.75rem",
          }}
          onClick={clearDiscount}
        />
      </CouponTag>
      <Small as="div">{display}</Small>
    </CartFooterLineItem>
  );
};
CartDiscount.propTypes = {
  label: PropTypes.string,
  coupon: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onClear: PropTypes.func,
};
