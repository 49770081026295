import React, { useMemo } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import { getImageSrc } from "~/utils/cloudinary";
import { Subheadline } from "~/components/typography";
import { Grid } from "~/components/grid";

import {
  NavigationWrapper,
  NavigationList,
  NavigationFeature,
  NavigationItem,
  NavigationDropdownContent,
} from "./navigation.style";

const collectionQuery = graphql`
  {
    allShopifyCollection(
      filter: { productsCount: { gt: 1 } },
      sort: { fields: updatedAt }
    ) {
      nodes {
        id
        title
        handle
        storefrontId
      }
    }
  }
`;

const collections = [
  { label: "Christmas", to: "/collections/christmas" },
  { label: "Hanukkah", to: "/collections/hanukkah" },
  { label: "Fall", to: "/collections/fall" },
  { label: "Birthday", to: "/collections/birthday" },
  { label: "Just Because", to: "/collections/just-because" },
  { label: "Friendship", to: "/collections/friendship" },
  { label: "Encouragement", to: "/collections/encouragement" },
  { label: "Congratulations", to: "/collections/congratulations" },
  { label: "Baby", to: "/collections/new-baby" },
  { label: "Thank You", to: "/collections/thank-you" },
  { label: "Love", to: "/collections/love" },
  { label: "Miss You", to: "/collections/miss-you" },
  { label: "Wedding", to: "/collections/wedding" },
  { label: "Graduation", to: "/collections/graduation" },
  { label: "Father's Day", to: "/collections/fathers-day" },
  { label: "Mother's Day", to: "/collections/mothers-day" },
  { label: "Valentine's Day", to: "/collections/valentines-day" },
  { label: "Holidays", to: "/collections/holidays" },
  { label: "Sympathy", to: "/collections/sympathy" },
  { label: "Get Well ", to: "/collections/get-well" },
  { label: "Breakups", to: "/collections/breakups" },
  { label: "Covid Related", to: "/collections/covid-related" },
];

export const NavigationCards = ({ closeMenu }) => {
  const {
    allShopifyCollection: { nodes },
  } = useStaticQuery(collectionQuery);
  const imagePath = getImageSrc("thegoodsnail7-small_nhgoyc", "dpr_2");
  const navigationItems = useMemo(() => {
    return collections
      .filter(c =>
        nodes.find(n => `/collections/${n.handle}` === c.to),
      )
      .map(({
        label,
        to,
      }) => (
        <NavigationItem key={label}>
          <Link
            to={to}
            onClick={closeMenu}
          >
            {label}
          </Link>
        </NavigationItem>
      ));
  }, [nodes, closeMenu]);

  return (
    <NavigationDropdownContent>
      <Grid>
        <NavigationWrapper>
          <NavigationFeature src={imagePath} to="/search">
            <Subheadline>Shop All Cards</Subheadline>
          </NavigationFeature>
          <NavigationList>
            {navigationItems}
          </NavigationList>
        </NavigationWrapper>
      </Grid>
    </NavigationDropdownContent>
  );
};
