import styled, { css, keyframes } from "styled-components";

const heartAnimation = keyframes`
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
`;

export const LoaderWrapper = styled.div`
  --size: ${({ size }) => `${size}px`};

  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 120ms linear 0s;
  opacity: 0;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 1.0;
    `}
`;

export const LoaderContainer = styled.div`
  display: inline-block;
  position: relative;
  width: var(--size); // 80px;
  height: var(--size); // 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
`
export const LoaderItem = styled.div`
  top: calc(var(--size) * 0.4); // 32px;
  left: calc(var(--size) * 0.4); // 32px;
  position: absolute;
  width: calc(var(--size) * 0.4); // 32px
  height: calc(var(--size) * 0.4); // 32px
  background-color: ${({ theme }) => theme.colors.accentOne};
  animation: ${heartAnimation} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

  &:after,
  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: calc(var(--size) * 0.4);
    height: calc(var(--size) * 0.4);
    background-color: ${({ theme }) => theme.colors.accentOne};
  }

  &:before {
    left: calc(var(--size) * 0.3 * -1);
    border-radius: 50% 0 0 50%;
  }

  &:after {
    top: calc(var(--size) * 0.3 * -1);
    border-radius: 50% 50% 0 0;
  }
`;
