import styled, { css } from "styled-components";

export const Rotate = styled.span`
  display: inherit;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate}deg);
    `};
`;
