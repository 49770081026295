import { useEffect, useRef, useState } from "react";

const defaultOptions = {
  rootMargin: "25% 0% 25%",
  threshold: 0,
};

export const useIntersectionObserver = (overrideOptions = {}) => {
  const observerRef = useRef();
  const intersectionRef = useRef();
  const [isInView, setIsInView] = useState(false);
  const options = Object.assign(defaultOptions, overrideOptions);

  useEffect(() => {
    if (!isInView && intersectionRef.current) {
      observerRef.current = new IntersectionObserver(elements => {
        elements.forEach(({ isIntersecting }) => {
          setIsInView(isIntersecting);
        });
      }, options);

      observerRef.current.observe(intersectionRef.current);
    }

    return () => {
      observerRef.current?.disconnect();
    }
  }, [options, isInView, setIsInView]);

  return [
    intersectionRef,
    isInView,
  ];
}
