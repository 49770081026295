import React from "react";
import PropTypes from "prop-types";

import { removeWidows } from "~/utils/string";
import { formatCurrency } from "~/utils/currency";
import { Small, Body, BodyHeadline } from "~/components/typography";
import { ProductItemImage } from "./product-item-image.component";

import {
  ProductItemWrapper,
  ProductItemContent,
} from "./product-item.style";

export const ProductItem = ({
  vendor,
  title,
  description,
  priceRangeV2,
  images,
  categories,
  slug
}) => {
  const price = priceRangeV2?.minVariantPrice?.amount || 0;
  const [image] = Array.isArray(images) ? images : [];

  return (
    <ProductItemWrapper to={`/cards/${slug}`}>
      <ProductItemImage {...{ image }} />
      <ProductItemContent>
        {vendor && <Small>{vendor}</Small>}
        {title && <BodyHeadline as="div">{removeWidows(title)}</BodyHeadline>}
        {price && <Body as="div">{formatCurrency(price)}</Body>}
      </ProductItemContent>
    </ProductItemWrapper>
  );
}
ProductItem.defaultProps = {
  categories: [],
  images: [],
};
ProductItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  priceRangeV2: PropTypes.object,
  images: PropTypes.array,
  categories: PropTypes.array,
  slug: PropTypes.string,
};
