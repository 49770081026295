import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, pickBy } from "lodash";
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { navigate } from "gatsby";

import { useCart, useDiscount } from "~/hooks";
import { getQuantity } from "~/utils/products";
import { formatCurrency } from "~/utils/currency";
import { track } from "~/core/analytics";
import { HeroSubheadline, Subheadline } from "~/components/typography";

import { createCheckoutSession } from "./cart.requests";
import { CartItem } from "./cart-item.component";
import { CartLineItem } from "./cart-line-item.component";
import { CartDiscount, CartDiscountInput } from "./discount";
import {
  CartWrapper,
  CartList,
  CartEmpty,
  CartDiscountMessage,
  CartHeader,
  CartFooter,
} from "./cart.style";

const Cart = ({ onClose }) => {
  const cart = useCart();
  const { items: cartItems, subTotal } = cart;
  const {
    discount,
    nextDiscount,
    addDiscount,
    clearDiscount,
  } = useDiscount();

  const [isLoading, setIsLoading] = useState(false);
  const quantity = getQuantity(cartItems);
  const discountValue = discount.amount_off
    ? discount.amount_off / 100
    : discount.percent_off
      ? subTotal.value * (discount.percent_off / 100)
      : 0;
  const grandTotal = formatCurrency(
    Math.max(subTotal.value - discountValue, 0),
  );

  if (!cart) {
    return <></>;
  }

  const onCheckout = async event => {
    event.preventDefault();
    setIsLoading(true);

    const lineItems = cartItems.map(({
      title,
      unitPrice,
      quantity,
      image,
      metadata = {},
    }) => ({
      price_data: {
        currency: 'usd',
        product_data: pickBy({
          name: title,
          images: [image.src],
          metadata,
        }, Boolean),
        unit_amount: unitPrice * 100, // Remove decimal for Stripe
      },
      quantity,
    }));

    track.checkout(
      cartItems.map((p, i) => ({
        id: p.sku,
        name: p.title,
        brand: p.vendor,
        variant: isEmpty(p.metadata) ? 'Blank' : 'Personalized',
        list_position: i + 1,
        quantity: p.quantity,
        price: p.unitPrice,
      })),
      discount.code,
    );

    try {
      const { url } = await createCheckoutSession(lineItems, discount.code);
      window.location = url;
    }
    catch (err) {
      setIsLoading(false);
    }
  }

  return (
    <CartWrapper>
      <CartHeader>
        <div>
          <HeroSubheadline style={{ fontSize: "1.8rem" }}>Shopping Bag</HeroSubheadline>
        </div>
        {onClose && (
          <IconButton
            aria-label="close"
            color="secondary"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </CartHeader>

      {(cartItems.length > 0 && nextDiscount) && (
        <CartDiscountMessage to="/search" onClick={onClose}>
          Buy {nextDiscount.quantity - quantity} more to save {nextDiscount.display}
        </CartDiscountMessage>
      )}

      <CartList>
        {cartItems.length === 0 && (
          <CartEmpty>
            <Subheadline>Your bag is empty.</Subheadline>
            <Subheadline>Fill it with some good mail.</Subheadline>
            <br />
            <Button
              variant="outlined"
              sx={{ minWidth: 200 }}
              onClick={() => navigate("/search")}
            >
              Shop all cards
            </Button>
          </CartEmpty>
        )}

        {cartItems.map(cartItem => (
          <CartItem
            key={`${cartItem.id}:${JSON.stringify(cartItem.metadata)}`}
            {...cartItem}
          />
        ))}
      </CartList>

      {cartItems.length > 0 && (
        <CartFooter>
          <CartDiscountInput {...{ discount, addDiscount }} />
          <CartLineItem label="Subtotal" value={subTotal.display} />
          <CartDiscount {...{ discount, clearDiscount }} />
          <CartLineItem label="Shipping" value={0} discount />

          <LoadingButton
            loading={isLoading}
            loadingPosition="center"
            variant="contained"
            onClick={onCheckout}
            fullWidth
          >
            Secure Checkout  —  {grandTotal}
          </LoadingButton>
        </CartFooter>
      )}
    </CartWrapper>
  );
};
Cart.propTypes = {
  onClose: PropTypes.func,
};

export default memo(Cart);
