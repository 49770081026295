import React, { useEffect, useState } from "react";
import { IS_CLIENT } from "~/constants";
import { getQuery } from "~/utils/url";
import {
  PromoBannerWrapper,
} from "./promo-banner.style";

const PROMO_BANNERS = [
  {
    name: "Product Hunt",
    variant: "producthunt",
    message: (
      <>Welcome Product Hunt! Use code <strong>HUNTERSMAIL</strong> for 25% off</>
    ),
  },
  {
    name: "default",
    variant: "accentFour",
    message: (
      <><strong>Shipping will be delayed due to the Holidays</strong></>
    ),
    endDate: Date.parse('12/29/2021'),
  },
];

export const PromoBanner = () => {
  const [promoVariant, setPromoVariant] = useState(
    IS_CLIENT && sessionStorage.getItem("promoVariant"),
  );
  const promoBanner =
    PROMO_BANNERS.find(({ variant }) => variant === promoVariant) ||
    PROMO_BANNERS.find(({ name }) => name === "default");

  useEffect(() => {
    const variant = getQuery("ref");
    if (variant && IS_CLIENT) {
      sessionStorage.setItem("promoVariant", variant);
      setPromoVariant(variant);
    }
  }, [setPromoVariant]);

  // Handle end dates
  if (!promoBanner || (promoBanner.endDate && promoBanner.endDate < new Date().getTime())) {
    return null;
  }

  return promoBanner && (
    <PromoBannerWrapper
      $variant={promoBanner.variant}
    >
      {promoBanner.message}
    </PromoBannerWrapper>
  );
}
