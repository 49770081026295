import React from "react";
import PropTypes from "prop-types";

import {
  LoaderWrapper,
  LoaderContainer,
  LoaderItem,
} from "./loader.style";

export const Loader = ({ size, isLoading }) => (
  <LoaderWrapper {...{ size, isLoading }}>
    <LoaderContainer>
      <LoaderItem />
    </LoaderContainer>
  </LoaderWrapper>
)
Loader.defaultProps = {
  size: 60,
};
Loader.propTypes = {
  size: PropTypes.number,
  isLoading: PropTypes.bool,
};
