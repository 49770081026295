import styled from "styled-components";

import logoDark from "~/assets/images/tgs-logo-black.png";
import logoLight from "~/assets/images/tgs-logo-white.png";

export const Logo = styled.img.attrs(({ light }) => ({
  src: light ? logoLight : logoDark,
  alt: "The Good Snail Logo",
}))`
  height: auto;
  max-height: ${({ height }) => height || "38px"};
  min-width: 100px;
`;
