import { css } from "styled-components";

import { MEDIA_NO_TOUCH } from "~/utils/media";

export const buttons = {
  primary: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};

    svg {
      margin-right: -0.5rem;
      margin-left: 0.5rem;
    }

    ${MEDIA_NO_TOUCH} {
      &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.accentThree};
        border-color: ${({ theme }) => theme.colors.accentThree};
      }
    }
  `,

  secondary: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.white};

    ${MEDIA_NO_TOUCH} {
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.white};
      }
    }
  `,

  secondaryDark: css`
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.primary};

    ${MEDIA_NO_TOUCH} {
      &:hover {
        // color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.accentOne};
        // border-color: ${({ theme }) => theme.colors.primary};
      }
    }
  `,

  simple: css`
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    border-color: transparent;

    ${MEDIA_NO_TOUCH} {
      &:hover {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  `,

  circle: css`
    padding: 0.5rem;
    background-color: transparent;
    border-radius: 50%;

    ${MEDIA_NO_TOUCH} {
      &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }
  `,
}
