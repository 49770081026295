import styled, { css } from "styled-components";
import { Link } from "gatsby";

import { IS_SMALL } from "~/utils/media";
import {
  BodyHeadline,
  Small,
  SmallHeadline,
} from "~/components/typography";
import { QuantityWrapper } from "~/components/form/quantity-simple";

export const CartDiscountMessage = styled(Link)`
  ${({ theme }) => theme.typography.bodyHeadline};
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentFour};
`;

export const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0 3rem;
`;

export const CartList = styled.ul`
  overflow: scroll;
  flex-grow: 1;
  margin: 0;
  padding-top: 2rem;
`;

export const CartEmpty = styled.div`
  position: relative;
  top: 50%;
  margin: 2rem;
  padding: 4rem 0;
  text-align: center;
  // border: 4px solid ${({ theme }) => theme.colors.accentOne};
  border-radius: 8px;
  transform: translateY(calc(-50% - 2rem));
`;

export const CartItemWrapper = styled.li`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 0 2rem 1rem;

  ${({ isLoading }) => isLoading && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  ${IS_SMALL} {
    padding: 0 1rem 1rem;
  }
`;

export const CartItemImage = styled.figure`
  overflow: hidden;
  display: block;
  width: 104px;
  min-width: 104px;
  min-height: 104px;
  border-radius: 8px;

  ${IS_SMALL} {
    width: 108px;
    min-width: 108px;
    min-height: 108px;
  }
`;

export const CartItemInfo = styled.div`
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 1rem;

  ${BodyHeadline} {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${QuantityWrapper} {
    margin-left: -5px
  }
`;

export const CartItemAttribute = styled(Small).attrs(() => ({
  as: "div",
}))`
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0.25rem 0;

  & + & {
    margin-top: 0;
  }
`;

export const CartHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem 1rem;

  & > *:first-child {
    flex-grow: 1;
  }

  ${IS_SMALL} {
    padding: 0 1rem 1rem;
  }
`;

export const CartFooter = styled.div`
  padding: 1rem 2rem 0;
  box-shadow: 0 -4px 16px rgba(0,0,0, 0.1);

  svg {
    display: block;
    margin-left: auto;
  }

  ${IS_SMALL} {
    padding: 1rem 1rem 0;
  }
`;

export const CartFooterLineItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem 0.5rem;

  ${Small} {
    font-weight: 700;
    line-height: calc((20 / var(--base-font-size)) * 1rem);
    ${({ $discount, theme }) =>
      $discount &&
      css`
        color: ${theme.colors.success};
      `}
  }

  &:last-of-type {
    padding-bottom: 1.5rem;
  }

  & > *:last-child {
    flex-grow: 1;
    text-align: right;
  }
`;

export const CouponLabel = styled(SmallHeadline).attrs(() => ({
  as: "div",
}))`
  overflow: hidden;
  margin: 0 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CouponTag = styled.div`
  display: flex;
  align-items: center;
  max-width: 58%;
  margin-left: 1rem;
  padding: 0.2rem 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentTwo};
  border-radius: 1rem;
`;

