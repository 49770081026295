export const formatAddress = (address = {}) => {
  const { name, street1, street2, city, state, zip, zip4, country } = address;
  return [
      name,
      street1,
      street2,
      city,
      state,
      `${zip}${zip4 ? `-${zip4}` : ""}`,
      country,
    ]
    .filter(v => !!(v))
    .join(", ");
}
