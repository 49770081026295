import styled from "styled-components";
import { Link } from "gatsby";

import { MEDIA_NO_TOUCH } from "~/utils/media";
import { BodyHeadline } from "~/components/typography";

export const ProductItemImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: ${({ theme }) => theme.colors.neutral};

  figure {
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ $isLandscape }) => $isLandscape ? "90%" : "70%"};
    transform: translate(-50%, -50%);
  }
`;

export const ProductItemWrapper = styled(Link)`
  cursor: pointer;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};

  ${MEDIA_NO_TOUCH} {
    figure {
      transition: transform 0.12s ease-in-out 0s;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.accentTwo};

      figure {
        transform: translate(-50%, -50%) scale(1.02) rotateZ(-2deg);
      }
    }
  }
`;

export const ProductItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 2rem;
  text-align: center;

  ${BodyHeadline} {
    margin: 0.2rem 0 0.6rem;
  }
`;

// export const ProductItemFigure = styled.figure`
//   width: 100%;
//   background-color: ${({ theme }) => theme.colors.accentTwo};
// `;
