import React from "react";

import { Rotate } from "./icons.style";

export const SkipArrowIcon = ({ rotate }) => (
  <Rotate {...{ rotate }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M6 18l8.5-6L6 6v12zm2-8.14L11.03 12 8 14.14V9.86zM16 6h2v12h-2z"
        fill="currentColor"
      />
    </svg>
  </Rotate>
);
