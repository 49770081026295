import axios from "axios";

export const provinceLookup = async countryCode => {
  let response = { data: {} };

  try {
    response = await axios.get(
      `${process.env.GATSBY_FIREBASE_FUNCTIONS_URL}/provinceLookup?countryCode=${countryCode}`,
    );
  }
  catch (err) {
    console.error(err);
    return Promise.reject();
  }

  return Promise.resolve(response.data);
}

export const cityStateLookup = async (zip) => {
  let response = { data: {} };

  try {
    response = await axios({
      method: "get",
      url: `/api/usps/city-state-lookup?zip=${zip}`,
      headers: {
        "content-type": "application/json",
      },
    });
  }
  catch (err) {
    console.error(err);
  }

  return Promise.resolve(response.data);
}

export const verify = async (data) => {
  let response = { data: {} };

  try {
    response = await axios({
      method: "POST",
      url: `/api/usps/verify`,
      headers: {
        "content-type": "application/json",
      },
      data,
    });
  }
  catch (err) {
    console.error(err);
  }

  return Promise.resolve(response.data);
}
