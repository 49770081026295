import styled, { css, createGlobalStyle } from "styled-components";

import { IS_MEDIUM, IS_SMALL } from "~/utils/media";
import { ButtonType } from "~/components/button";

export const ModalGlobal = createGlobalStyle`
  ${({ isVisible }) => isVisible && css`
    html {
      overflow: hidden;
      height: 100vh;
    }
  `};
`;

export const ModalOverlay = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndexes.overlay};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.accentTwo};
  transition: opacity 0.12s linear 0s;
  opacity: 0;

  ${({ isVisible }) => isVisible && css`
    pointer-events: auto;
    opacity: 0.4;
  `};
`;

export const ModalWrapper = styled.div`
  overflow: auto;
  pointer-events: none;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndexes.overlay + 1};
  top: 50%;
  left: 50%;
  width: 50vw;
  max-width: 480px;
  max-height: 100vh;
  transform: translate(-50%, -60%);
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.custom.shadows.three};
  transition: opacity 0.12s cubic-bezier(.29,.26,.11,.99) 0s;
  transition-property: opacity, transform;
  border-radius: 8px;
  opacity: 0;

  ${({ isVisible }) => isVisible && css`
    pointer-events: auto;
    transform: translate(-50%, -50%);
    opacity: 1.0;
  `};

  ${IS_MEDIUM} {
    width: 90vw;
  }

  ${IS_SMALL} {
    max-height: 80vh;
  }
`;

export const ModalTitle = styled.div`
  padding: 1.5rem;
`;

export const ModalContent = styled.div`
  padding: 0 1.5rem 1.5rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0 0;

  ${ButtonType} {
    margin-top: 0;
    margin-left: 1rem;
  }

  &:empty {
    padding: 0;
    margin-top: 0;
    border-top: none;
  }

  ${IS_SMALL} {
    flex-direction: column-reverse;
    margin-top: 1rem;
    padding: 1.5rem 0 0;

    ${ButtonType} {
      width: 100%;
      margin-left: 0;
      text-align: left;
    }

    ${ButtonType} + ${ButtonType} {
      margin-bottom: 1rem;
    }
  }
`;
