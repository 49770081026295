import styled, { css } from "styled-components";

export const CardWrapper = styled.figure.attrs(({ alt }) => ({
  role: "img",
  "data-label": alt || "Card",
}))`
  display: block;
  width: ${({ width }) => `${width}`};

  ${({ rotate }) => rotate && css`
    transform: rotateZ(${rotate}deg);
    perspective: 1000px;
  `};
`;

export const CardFront = styled.div`
  position: relative;
  display: block;
  width: 100%;
  transform: rotateY(0deg);
  transform-origin: 0 50%;
  box-shadow: 0 2.6% 12% rgba(0,0,0, 0.16);
  backface-visibility: hidden;
`;

export const CardBack = styled(CardFront)``;

export const CardArt = styled.img`
  position: relative;
  display: block;
  width: 100%;
`;

export const Shadow = styled.div`
  --card-height: 10%;

  position: absolute;
  z-index: -1;
  left: 50%;
  bottom: 8%;
  width: calc(100% - 4%);
  height: var(--card-height);
  background-color: transparent;
  box-shadow: 0 18px 8px rgba(0,0,0, 0.2);
  transform: translate(-50%, var(--card-height)) skew(0, 2.8deg);

  ${({ $isLandscape }) =>
    $isLandscape &&
    css`
      top: 0;
      bottom: 0;
      height: auto;
      box-shadow: 0 8px 12px rgba(0,0,0, 0.2);
      transform: translate(-50%,0) rotateX(-4deg);
      transform-style: preserve-3d;
    `};
`;
