import React from "react";

import { AddressProvider } from "./address.provider";
import { AddressConfirm } from "./address-confirm.component";
import { AddressForm } from "./address-form.component";

export const Address = (props) => {
  return (
    <AddressProvider {...props}>
      <AddressConfirm />
      <AddressForm />
    </AddressProvider>
  )
};
