import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import uniqBy from "lodash/uniqBy";

import { Grid } from "~/components/grid";

import {
  NavigationWrapper,
  NavigationList,
  NavigationItem,
  NavigationDropdownContent,
} from "./navigation.style";

const collectionQuery = graphql`
  {
    allShopifyProduct(
      sort: { fields: updatedAt }
    ) {
      nodes {
        id
        vendor
        vendorSlug
      }
    }
  }
`;

export const NavigationDesigners = ({ closeMenu }) => {
  const {
    allShopifyProduct: { nodes: products },
  } = useStaticQuery(collectionQuery);
  const vendors = uniqBy(products, p => p.vendorSlug);

  return (
    <NavigationDropdownContent>
      <Grid>
        <NavigationWrapper>
          <NavigationList>
            {vendors.map(({ id, vendor, vendorSlug }) => (
              <NavigationItem key={id}>
                <Link
                  to={`/designers/${vendorSlug}`}
                  onClick={closeMenu}
                >
                  {vendor}
                </Link>
              </NavigationItem>
            ))}
          </NavigationList>
        </NavigationWrapper>
      </Grid>
    </NavigationDropdownContent>
  );
};
