import React from "react";

import { Rotate } from "./icons.style";

export const ChevronIcon = ({ rotate }) => (
  <Rotate {...{ rotate }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z"
        fill="currentColor"
      />
    </svg>
  </Rotate>
);
