import React, { Fragment, memo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';

import { useUpdateItemQuantity } from "~/hooks";
import { track } from "~/core/analytics";
import { ProductItemImage } from "~/components/products/product-item";
import { BodyHeadline } from "~/components/typography";
import { QuantitySimple } from "~/components/form";
import { Truncate } from "~/components/truncate";

import {
  CartItemWrapper,
  CartItemImage,
  CartItemInfo,
  CartItemAttribute,
} from "./cart.style";

const RecipientTruncate = ({ recipient }) => {
  if (!recipient) {
    return null;
  }

  const recipientFormatted = recipient.split(/,\s|,/);
  const recipientFirst = recipientFormatted[0];
  const recipientFull = recipientFormatted.map(r => (
    <Fragment key={r}>{r}<br /></Fragment>
  ));

  return (
    <Truncate
      preview={`Recipient: ${recipientFirst}`}
      content={recipientFull}
    />
  );
}

export const CartItem = memo(({
  image,
  metadata,
  price,
  quantity,
  slug,
  sku,
  title,
  vendor,
  id: variantId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { message, recipient, font } = metadata;
  const updateItemQuantity = useUpdateItemQuantity();
  const onQuantityChange = useCallback(async (value) => {
    setIsLoading(true);
    try {
      updateItemQuantity({ id: variantId, metadata }, value);
      if (value === 0) {
        track.removeFromCart({
          id: sku,
          name: title,
          brand: vendor,
          quantity,
          price,
        });
      }
    } catch (err) {
      console.error("There was a problem updating that item's quantity. ", err);
    }
    setIsLoading(false);
  }, [
    variantId,
    metadata,
    sku,
    title,
    vendor,
    quantity,
    price,
    setIsLoading,
    updateItemQuantity,
  ]);

  const onRemove = useCallback(() => {
    return onQuantityChange(0);
  }, [onQuantityChange]);

  return (
    <CartItemWrapper {...{ isLoading }}>
      <CartItemImage>
        <ProductItemImage {...{ image }} />
      </CartItemImage>
      <CartItemInfo>
        <Link to={`/cards/${slug}`}>
          <BodyHeadline as="div">{title}</BodyHeadline>
        </Link>

        {recipient && (
          <CartItemAttribute>
            <RecipientTruncate {...{ recipient }} />
          </CartItemAttribute>
        )}

        {message && (
          <CartItemAttribute>
            <Truncate content={`Message: ${message}`} />
          </CartItemAttribute>
        )}

        {font && (
          <CartItemAttribute
            style={{
              textTransform: "capitalize",
            }}
          >
            Style: {font}
          </CartItemAttribute>
        )}

        <CartItemAttribute
          style={{ fontWeight: 900 }}
        >
          {price.display}
        </CartItemAttribute>

        <QuantitySimple
          value={quantity}
          onChange={onQuantityChange}
          clearable
        />
      </CartItemInfo>
      <IconButton
        aria-label="remove item"
        color="secondary"
        onClick={onRemove}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </CartItemWrapper>
  );
});
CartItem.defaultProps = {
  customAttributes: [],
};
CartItem.propTypes = {
  customAttributes: PropTypes.array,
};
