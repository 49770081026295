import React from "react";
import PropTypes from "prop-types";

import { Small, SmallHeadline } from "~/components/typography";
import { CartFooterLineItem } from "./cart.style";

export const CartLineItem = ({ label, value, discount }) => (
  <CartFooterLineItem $discount={discount}>
    <SmallHeadline as="div">{label}</SmallHeadline>
    <Small as="div">{(discount && value > 0) && "-"}{value || "FREE"}</Small>
  </CartFooterLineItem>
);
CartLineItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  discount: PropTypes.bool,
};
