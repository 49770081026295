import styled, { css } from "styled-components";

export const TruncateWrapper = styled.div`
  cursor: pointer;
  display: flex;
  font-size: inherit;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const TruncateCopy = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $isActive }) =>
    $isActive
      ? css`
          white-space: normal;
          padding-bottom: 0.5rem;
        `
      : css`
          white-space: nowrap;
        `}
`;

export const TruncateToggle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1;

  svg {
    font-size: 1rem;
  }
`;
