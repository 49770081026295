import styled, { css } from "styled-components";
import { Link } from "gatsby";

const ButtonStyles = css`
  ${({ theme, size }) => theme.typography[size || "buttonBold"]};
  ${({ $fullWidth }) => $fullWidth && css`width: 100%`};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1.5em;
  text-align: center;
  line-height: 24px;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  transition: background-color 120ms linear 0s;
  transition-property: color, background-color, border-color;
  white-space: nowrap;
  appearance: none;

  ${({ theme, variant }) => theme.buttons[variant || "primary"]};

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  * {
    pointer-events: none;
  }

  & > *:first-child {
    flex-grow: 1;
  }

  & + & {
    margin-top: 1rem;
  }
`;

export const AnchorType = styled.a`
  ${ButtonStyles};
`;

export const ButtonType = styled.button`
  ${ButtonStyles};
`;

export const LinkType = styled(Link)`
  ${ButtonStyles};
`;
