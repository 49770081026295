import React from "react";

export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g>
      <rect
        fill="none"
        width="24"
        height="24"
      />
    </g>
    <g>
      <path
        d="M20.29,18.88l-5.56-5.56c1.13-1.55,1.63-3.58,0.98-5.74c-0.68-2.23-2.57-3.98-4.85-4.44C6.21,2.2,2.2,6.22,3.14,10.86 c0.46,2.29,2.21,4.18,4.44,4.85c2.16,0.65,4.19,0.15,5.74-0.98l5.56,5.56c0.39,0.39,1.02,0.39,1.41,0l0,0 C20.68,19.9,20.68,19.27,20.29,18.88z M5,9.5C5,7.01,7.01,5,9.5,5S14,7.01,14,9.5S11.99,14,9.5,14S5,11.99,5,9.5z"
      />
    </g>
  </svg>
)
