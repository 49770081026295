import styled, { css } from "styled-components";

import { ButtonType, LinkType } from "~/components/button";

const SpacingStyles = css`
  color: ${({ theme }) => theme.colors.primary};

  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 0.5em;
  }

  & + p {
    margin-top: 0.5em;
  }

  & + ${LinkType},
  & + ${ButtonType} {
    margin-top: 1rem;
  }
`;

export const HeroHeadline = styled.h1`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.heroHeadline};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;

export const HeroSubheadline = styled.h2`
  ${SpacingStyles};
  position: relative;
  display: inline;
  ${({ theme }) => theme.typography.heroSubheadline};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0.4rem;
    width: 100%;
    height: 8px;
    background-color: ${({ theme }) =>
      theme.colors.accentOne};
    border-radius: 48px;
    opacity: 0.8;
  }
`;

export const HeroBody = styled.p`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.heroBody};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;

export const Headline = styled.h3`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.headline};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;

export const Subheadline = styled.h4`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.subheadline};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;

export const Body = styled.p`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.body};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;

export const BodyHeadline = styled.p`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.bodyHeadline};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;

export const Small = styled.p`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.small};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;

export const SmallHeadline = styled.p`
  ${SpacingStyles};
  ${({ theme }) => theme.typography.smallHeadline};
  color: ${({ theme, color }) =>
    theme.colors[color] || "inherit"};
`;
