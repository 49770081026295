import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Subheadline } from "~/components/typography";
import { Button } from "~/components/button";

import {
  ModalGlobal,
  ModalWrapper,
  ModalTitle,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "./modal.style";

export const Modal = ({
  children,
  title,
  isVisible,
  onCancel,
  cancelLabel,
  onAccept,
  acceptLabel,
}) => {
  useEffect(() => {
    if (isVisible && window !== "undefined") {
      document.documentElement.scrollTo(0, window.scrollY);
    }
  }, [isVisible]);

  return (
    <>
      <ModalGlobal isVisible={isVisible} />
      <ModalWrapper isVisible={isVisible}>
        {title && (
          <ModalTitle>
            <Subheadline>
              {title}
            </Subheadline>
          </ModalTitle>
        )}
        <ModalContent>
          {children}
        </ModalContent>
        <ModalFooter>
          {cancelLabel && <Button label={cancelLabel} onClick={onCancel} variant="secondaryDark" />}
          {acceptLabel && <Button label={acceptLabel} onClick={onAccept} />}
        </ModalFooter>
      </ModalWrapper>
      <ModalOverlay
        isVisible={isVisible}
        onClick={onCancel}
      />
    </>
  )
};
Modal.propTypes = {
  title: PropTypes.string,
  acceptLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  isVisible: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};
