import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { createTheme } from "@mui/material/styles";

import { colors, palette } from "./colors";
import { zIndexes } from "./zIndexes";
import { typography } from "./typography";
import { buttons } from "./buttons";
import { shadows } from "./shadows";

const theme = createTheme({
  colors,
  palette, // material-ui
  buttons,
  typography,
  // shadows,
  zIndexes,
  custom: {
    shadows,
  },
});

export const useTheme = () => {
  return useContext(ThemeContext);
}

export default theme;
