import styled from "styled-components";

import { MEDIA_NO_TOUCH } from "~/utils/media";

export const QuantityWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;

  label {
    ${({ theme }) => theme.typography.bodyHeadline};
    display: block;
    flex-grow: 1;
    padding: 1rem 0;
  }
`;

export const QuantityInput = styled.div`
  display: flex;
  align-items: center;

  input {
    ${({ theme }) => theme.typography.subheadline};
    width: 3rem;
    text-align: center;
    background-color: transparent;
    outline: none;
    border: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
`;

export const QuantityButton = styled.button`
  cursor: pointer;
  position: relative;
  display: block;
  width: 28px;
  height: 28px;
  padding: 0;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 14px;
  appearance: none;
  outline: none;
  transition: opacity 0.2s linear 0s;
  transition-property: opacity, background-color;
  opacity: 0.4;

  * {
    vertical-align: middle;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -50%);
    transition: fill 0.2s linear 0s;
  }

  &[disabled] {
    opacity: 0.1;
  }

  ${MEDIA_NO_TOUCH} {
    &:hover:not([disabled]) {
      background-color: ${({ theme }) => theme.colors.primary};
      opacity: 1;

      svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
