import React from "react";
import { Link } from "gatsby";

import { useTheme } from "~/themes";
import { Logo } from "~/components/logo";
import { Grid } from "~/components/grid";
import { StripeLogo } from "~/components/icons";
import {
  BodyHeadline,
  Body,
  Small,
} from "~/components/typography";

import {
  FooterWrapper,
  FooterColumn,
  FooterBlock,
} from "./footer.style";

export const Footer = () => {
  const { colors } = useTheme();

  return (
    <FooterWrapper>
      <Grid>
        <FooterColumn>
          <Logo light />
        </FooterColumn>
        <FooterColumn>
          <FooterBlock>
            <BodyHeadline>Shop</BodyHeadline>
            <Body as="div"><Link to="/search">Cards</Link></Body>
            {/*<Body as="div"><Link to="/designers">Designers</Link></Body>*/}
          </FooterBlock>
          <FooterBlock>
            <BodyHeadline>Company</BodyHeadline>
            <Body as="div"><Link to="/story">Our Story</Link></Body>
            <Body as="div"><Link to="/mail-with-love">Mail with Love Charity</Link></Body>
          </FooterBlock>
        </FooterColumn>
        <FooterColumn>
          <BodyHeadline>Support</BodyHeadline>
          <Body as="div"><Link to="/faqs">FAQ's</Link></Body>
          <Body as="div"><Link to="/shipping-policies">Shipping Policies</Link></Body>
          <Body as="div"><Link to="/become-a-seller">Become a Seller</Link></Body>
        </FooterColumn>
        <FooterColumn>
          <BodyHeadline>Connect</BodyHeadline>
          <Body as="div"><a href="https://instagram.com/thegoodsnail" target="_blank" rel="noreferrer">Instagram</a></Body>
          <Body as="div"><a href="https://facebook.com/thegoodsnail" target="_blank" rel="noreferrer">Facebook</a></Body>
          <Body as="div"><a href="https://pinterest.com/TheGoodSnail" target="_blank" rel="noreferrer">Pinterest</a></Body>
          <Body as="div"><a href="mailto:hello@thegoodsnail.com">Email</a></Body>
        </FooterColumn>
      </Grid>
      <Grid>
        <Small>
          Copyright © 2021, The Good Snail. | <Link to="/terms-of-service">Terms of Service</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
        </Small>
        <StripeLogo fill={colors.white} />
      </Grid>
    </FooterWrapper>
  );
};
