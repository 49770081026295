export const QUERY = {
  XLARGE: '(min-width: 1440px)',
  LARGE: '(max-width: 1439px)',
  MEDIUM: '(max-width: 1023px)',
  SMALL: '(max-width: 767px)',
};

export const MEDIA_TOUCH = '@media (hover: none) and (pointer: coarse)';
export const MEDIA_NO_TOUCH = '@media (hover: hover) and (pointer: fine)';
export const IS_XLARGE = `@media screen and ${QUERY.XLARGE}`;
export const IS_LARGE = `@media screen and ${QUERY.LARGE}`;
export const IS_MEDIUM = `@media screen and ${QUERY.MEDIUM}`;
export const IS_SMALL = `@media screen and ${QUERY.SMALL}`;
