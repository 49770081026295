import pick from "lodash/pick";

/**
 * Makes the last space in a sentence non-breaking as to avoid widows
 *
 * @param str {String} - String to remove widow from
 *
 * @return {String} - Non widowed string
 */
export const removeWidows = (str = "") =>
  typeof str === "string"
    ? str.replace(/\s+(?=\S*$)/ig, " ")
    : str;

export const toKebabCase = (value = "") =>
  value.trim().toLowerCase()
    .replace(/^[^a-zA-Z0-9]*|[^a-zA-Z0-9]*$/ig, "")
    .replace(/[^a-zA-Z0-9]/ig, "-");

export const cleanMetaDescription = (description = "") => {
  return description.replace(/(^td\s|{.{1,}})/ig, "").trim();
}

export const stripHTML = (string = "") => {
  return string.replace(/(<([^>]+)>)/gi, "");
}

/**
 * Makes the last space in a sentence non-breaking as to avoid widows
 *
 * @param str {String} - String to remove widow from
 *
 * @return {String} - Non widowed string
 */
// export const removeWidows = (str = "") =>
//   str.replace(/\s(?=\w*(\r|\n|\W)*?$)/, " ");

/**
 * Allows for simple handlebars style templating
 *
 * @example
 *  `parseStringTemplate("{{total}} shades available", { total: 12 })`
 *
 * @params template {String} - Template string
 * @params data {Object} - Key/Value object of data to replace within the string
 *
 * @returns {String} - fully parsed string
 */
export const parseStringTemplate = (template = "", data = {}) => {
  const options = template.match(/{{.{0,}?}}/gi);
  let parsed = template;
  (options || []).forEach((option) => {
    const optionPath = option.replace(/{{|}}/gi, "");
    const keys = optionPath.split(".");
    // const value = pathOr("", keys, data);
    const value = pick(data, keys) || "";
    parsed = parsed.replace(option, value);
  });
  return parsed;
};

/**
 * Creates a properly formatted list from an array of strings
 *
 * @param {array} list - An array of strings to put into a list
 *
 * @return {string} title - A title based on given array elements (ex. Alkaline Trio, Belle Reve, and Paper Dolls)
 */
export const arrayToSentence = (list = []) => {
  if (!list.length || list.length === 1) {
    return list.join("");
  }

  list[list.length - 1] = `and ${list[list.length - 1]}`;

  return list.map((l) => l.trim()).join(list.length > 2 ? ", " : " ");
};
