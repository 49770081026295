import { useCallback, useEffect, useMemo, useRef } from "react";
import isEmpty from "lodash/isEmpty";

import { useStore, actions } from "~/store";
import { getQuantity } from "~/utils/products";
import { formatCurrency } from "~/utils/currency";
import { getCouponCode } from "~/components/cart";

const DISCOUNT_CODES = [
  { code: "GOODBUNDLE25", quantity: 25, value: 57.5, display: "$57.50" },
  { code: "GOODBUNDLE20", quantity: 20, value: 45, display: "$45" },
  { code: "GOODBUNDLE15", quantity: 15, value: 32.5, display: "$32.50" },
  { code: "GOODBUNDLE10", quantity: 10, value: 22, display: "$22" },
  { code: "GOODBUNDLE5", quantity: 5, value: 7.5, display: "$7.50" },
  { code: "GOODBUNDLE2", quantity: 2, value: 3, display: "$3" },
];

export const useDiscount = () => {
  const isLoading = useRef(false);
  const { state: { cart }, dispatch } = useStore();
  const cartItems = cart.items || [];
  const itemQuantity = getQuantity(cartItems);
  const previousQuantity = useRef(itemQuantity);

  const discountItem = useMemo(() => {
    return DISCOUNT_CODES
      .find(({ quantity }) => itemQuantity >= quantity) || {};
  }, [itemQuantity]);

  const nextDiscount = isEmpty(discountItem)
    ? DISCOUNT_CODES[DISCOUNT_CODES.length - 1]
    : DISCOUNT_CODES[DISCOUNT_CODES.indexOf(discountItem) - 1];

  const clearDiscount = useCallback(
    () => dispatch(actions.clearDiscount()),
    [dispatch],
  );

  const addDiscount = useCallback(async (couponName) => {
    let message = "Sorry, the coupon code you entered couldn't be applied.";
    let coupon = {};
    let minimumValue = 0.5;

    if (!couponName || cart.discount.code === couponName) {
      return Promise.resolve({ message });
    }

    if (!isLoading.current) {
      try {
        isLoading.current = true;
        ({ coupon } = await getCouponCode({ couponName }));
        isLoading.current = false;

        if (!coupon || isEmpty(coupon)) {
          return Promise.resolve({ message });
        }

        if ((coupon.amount_off / 100) + minimumValue >= cart.subTotal?.value - minimumValue) {
          return Promise.resolve({
            message: `Your order requires a minimum of ${formatCurrency((coupon.amount_off / 100) + minimumValue)}`,
          });
        }
      }
      catch (err) {
        console.error(err);
      }

      dispatch(actions.addDiscount(coupon));
      return Promise.resolve(coupon);
    }
  }, [dispatch, cart]);

  useEffect(() => {
    if (itemQuantity !== previousQuantity.current) {
      discountItem.code
        ? addDiscount(discountItem.code)
        : clearDiscount();
      previousQuantity.current = itemQuantity;
    }
  }, [itemQuantity, discountItem, addDiscount, clearDiscount]);

  return {
    addDiscount,
    clearDiscount,
    nextDiscount,
    discount: cart.discount,
  };
}
