import styled from "styled-components";

import { MEDIA_NO_TOUCH } from "~/utils/media";

export const Label = styled.label`
  ${({ theme }) => theme.typography.tinyHeadline};
  position: absolute;
  left: 0.5rem;
  padding: 0 0.5rem;
  transform: translateY(-50%);
  background: #fff;
  color: ${({ theme, $isError }) =>
    theme.colors[$isError ? "error" : "secondary"]};
  text-transform: uppercase;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  border: 2px solid ${({ theme, $isError }) =>
    theme.colors[$isError ? "error" : "neutralTwo"]};
  border-radius: 4px;
  outline: none;

  &:focus,
  &:active {
    border-color: ${({ theme, $isError }) =>
      theme.colors[$isError ? "error" : "accentTwo"]};

    & + ${Label} {
      color: ${({ theme, $isError }) =>
        theme.colors[$isError ? "error" : "accentTwo"]}
    }
  }

  ${MEDIA_NO_TOUCH} {
    &:hover {
      border-color: ${({ theme, $isError }) =>
        theme.colors[$isError ? "error" : "accentTwo"]};

      & + ${Label} {
        color: ${({ theme }) => theme.colors.accentTwo};
      }
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;

  * {
    transition: color, border-color 150ms linear;
  }
`;

export const Select = styled.select`
  cursor: pointer;
  width: 100%;
  min-width: 72px;
  height: 100%;
  padding: 0.75rem;
  border: 2px solid ${({ theme }) => theme.colors.neutralTwo};
  border-radius: 4px;

  &:active {
    border-color: ${({ theme }) => theme.colors.accentTwo};
  }

  ${MEDIA_NO_TOUCH} {
    &:hover {
      border-color: ${({ theme }) => theme.colors.accentTwo};

      & + ${Label} {
        color: ${({ theme }) => theme.colors.accentTwo};
      }
    }
  }
`;
