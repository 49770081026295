import React from "react";
import PropTypes from "prop-types";
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import {
  QuantityWrapper,
  QuantityInput,
} from "./quantity-simple.style";

export const QuantitySimple = ({
  label,
  value,
  clearable,
  onChange,
}) => {
  const minValue = clearable ? 0 : 1;
  const increment = () => onChange(value + 1);
  const decrement = () => onChange(Math.max(value - 1, minValue));
  const onInputChange = ({ target }) => onChange(Number(target.value));

  return (
    <QuantityWrapper>
      {label && <label>{label}</label>}
      <QuantityInput>
        <IconButton
          size="small"
          color="secondary"
          disabled={value <= minValue}
          onClick={decrement}
        >
          <RemoveIcon sx={{ fontSize: "calc((14 / var(--base-font-size)) * 1rem)" }} />
        </IconButton>
        <input
          type="number"
          value={value}
          min={`"${minValue}"`}
          max="999"
          onChange={onInputChange}
        />
        <IconButton
          size="small"
          color="secondary"
          onClick={increment}
        >
          <AddIcon sx={{ fontSize: "calc((14 / var(--base-font-size)) * 1rem)" }} />
        </IconButton>
      </QuantityInput>
    </QuantityWrapper>
  );
};
QuantitySimple.defaultProps = {
  initValue: 1,
  onChange: () => {},
};
QuantitySimple.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  value: PropTypes.number,
  clearable: PropTypes.bool,
  onChange: PropTypes.func,
};
