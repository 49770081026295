import { useRef } from "react";
import { useMount, useUnmount, useDebounce } from "react-use";
import { IS_CLIENT } from "~/constants";

export const useScroll = (callback, ms = 300) => {
  const canScroll = useRef();

  useDebounce(
    () => {
      if (canScroll.current && IS_CLIENT) {
        window.addEventListener('scroll', callback);
        return () => {
          window.removeEventListener('scroll', callback);
        }
      }
    },
    ms,
    [callback],
  );

  useMount(() => {
    canScroll.current = true;
  });

  useUnmount(() => {
    canScroll.current = false;

    if (IS_CLIENT) {
      window.removeEventListener('scroll', callback);
    }
  });
}
