import React from "react";

import { getIsLandscape } from "~/utils/cloudinary";
import { Card } from "~/components/card";

import {
  ProductItemImageWrapper,
} from "./product-item.style";

export const ProductItemImage = ({ image }) => {
  const isLandscape = getIsLandscape(image);
  const front = image?.src;
  const width = "100%";

  return (
    <ProductItemImageWrapper $isLandscape={isLandscape}>
      <Card {...{ width, front, isLandscape }} />
    </ProductItemImageWrapper>
  );
}
