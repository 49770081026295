import React from "react";

export const Blank = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M17 3H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7V5h10v14z"
      fill="currentColor"
    />
  </svg>
);
