import React from "react";
import PropTypes from "prop-types";
import values from "lodash/values";

import { BUTTON_VARIANTS } from "./button.constants";
import {
  AnchorType,
  ButtonType,
  LinkType,
} from "./button.style";

export const Button = ({
  label,
  icon,
  fullWidth,
  loading,
  ...props
}) => {
  const ButtonComponent = props.to
    ? LinkType
    : props.href
      ? AnchorType
      : ButtonType;

  return (label || icon) ? (
    <ButtonComponent {...props} $fullWidth={fullWidth}>
      {label && <span>{label}</span>}
      {icon}
    </ButtonComponent>
  ) : <></>;
};
Button.defaultProps = {
  variant: "primary",
};
Button.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(values(BUTTON_VARIANTS)),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};
