import styled from "styled-components";

import { ModalFooter } from "~/components/modal";

export const Form = styled.form`
  margin-bottom: 0;

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  ${ModalFooter} {
    justify-content: space-between;
    margin-top: 1rem;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;

    button:first-child {
      margin-left: 0;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  padding: 0.5rem 0;
`;

export const FormCell = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0.5rem;
`;
