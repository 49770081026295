import { omit } from "lodash";

export const getQuantity = items =>
  items.reduce((total, { quantity }) => {
    return total + quantity;
  }, 0);

export const normalizeCartItem = item => ({
  ...omit(item, [
    'images',
    'priceRangeV2',
    'variants',
    'descriptionHtml',
    'availableForSale',
  ]),
  unitPrice: Number(item.price),
  price: {
    display: `$${item.price}`,
    value: item.price,
  },
  image: item?.images?.[0] || {},
});

export const createDescription = ({
  message = '',
  recipient = '',
}) =>
  `${recipient}${recipient && message && ', '}${message}`;
