import React, {
  useCallback,
  useMemo,
  useState,
} from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useAddress } from "~/components/form/address";
import { BodyHeadline } from "~/components/typography";
import { ModalFooter } from "~/components/modal";
import { ArrowIcon } from "~/components/icons";
import { Form } from "~/components/form";

import { DEFAULT_VALUES } from "./address.constants";
import {
  AddressList,
  AddressOption,
  AddressTag,
} from "./address.style";

const Address = ({
  name,
  street1,
  street2,
  city,
  state,
  zip,
  zip4,
  isActive,
  onSelect,
}) => (
  <AddressOption
    $isActive={isActive}
    onClick={onSelect}
  >
    <AddressTag>
      {name && <>{name}<br /></>}
      {street1 && <>{street1}<br /></>}
      {street2 && <>{street2}<br /></>}
      {(city && state && zip) && (
        <>{city}, {state} {zip}{zip4 && `-${zip4}`}</>
      )}
    </AddressTag>
  </AddressOption>
);

export const AddressConfirm = () => {
  const {
    handleSubmit,
    setAddress,
    userAddress,
    verifiedAddress,
    setVerifiedAddress,
    formattedAddress,
    isLoader,
    reset,
    onSubmit,
  } = useAddress();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const onBack = () => {
    setVerifiedAddress(null);
  }

  const onSubmitCallback = useCallback(async () => {
    onSubmit(formattedAddress);
    setVerifiedAddress(null);
    reset(DEFAULT_VALUES);
  }, [
    formattedAddress,
    setVerifiedAddress,
    reset,
    onSubmit,
  ]);

  const addressOptions = useMemo(() => {
    return [
      verifiedAddress,
      userAddress,
    ].map((props, index) => {
      const onSelect = () => {
        setSelectedIndex(index);
        setAddress({
          name: userAddress?.name,
          ...props,
        });
      }
      return (
        <Address
          key={index}
          name={userAddress?.name}
          isActive={(selectedIndex === index)}
          onSelect={onSelect}
          {...props}
        />
      );
    });
  }, [verifiedAddress, userAddress, selectedIndex, setAddress]);

  return verifiedAddress ? (
    <Form
      action="#"
      onSubmit={handleSubmit(onSubmitCallback)}
      disabled={isLoader("submitting")}
    >
      <BodyHeadline
        color="secondary"
      >
        Confirm Recipient Address
      </BodyHeadline>
      <AddressList>
        {addressOptions}
      </AddressList>
      <ModalFooter>
        <Stack direction="row" spacing={2} style={{ width: "100%" }}>
          <Button
            variant="outlined"
            onClick={onBack}
            fullWidth
          >
            Change Address
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoader("submitting") ? "true" : undefined}
            loading={isLoader("submitting") ? "true" : undefined}
            endIcon={<ArrowIcon />}
            fullWidth
          >
            Add to Cart
          </Button>
        </Stack>

      </ModalFooter>
    </Form>
  ) : null;
}
