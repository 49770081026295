import React from "react";
import PropTypes from "prop-types";

import { getImageSrc } from "~/utils/cloudinary";
import { Grid } from "~/components/grid";
import { HeroHeadline } from "~/components/typography";

import {
  HeroTitleWrapper,
} from "./hero-title.style";

export const HeroTitle = ({ src, title, bgColor, align, height }) => {
  const imagePath = getImageSrc(src);
  return (
    <>
      <HeroTitleWrapper
        {...{
          imagePath,
          align,
          bgColor,
        }}
      >
        <Grid>
          {title && <HeroHeadline>{title}</HeroHeadline>}
        </Grid>
      </HeroTitleWrapper>
    </>
  )
};
HeroTitle.defaultProps = {
  height: 560,
};
HeroTitle.propTypes = {
  src: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  bgColor: PropTypes.string,
  height: PropTypes.number,
  align: PropTypes.oneOf([
    "left",
    "center",
    "right",
  ]),
};
