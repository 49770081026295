import { css } from "styled-components";

import { IS_SMALL } from "~/utils/media";

export const FONTS = [
  { value: "openSans", label: "Open Sans" },
  { value: "simple", label: "Simple" },
  { value: "script", label: "Script" },
  { value: "childsPlay", label: "Child's Play" },
  { value: "friendly", label: "Friendly" },
  { value: "bubble", label: "Bubble Nostalgia" },
  { value: "teen", label: "Teen Nostalgia" },
  { value: "punk", label: "Punk" },
  // { value: "merriweather", label: "Merriweather" },
  // { value: "jane", label: "Jane" },
  // { value: "coco", label: "CoCo" },
];

export const typography = {
  // Titles
  heroHeadline: css`
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-size: calc((64 / var(--base-font-size)) * 1rem);
    line-height: calc((68 / var(--base-font-size)) * 1rem);
    letter-spacing: 0em;
  `,
  heroSubheadline: css`
    font-family: "Merriweather", serif;
    font-weight: 500;
    font-size: calc((36 / var(--base-font-size)) * 1rem);
    line-height: calc((46 / var(--base-font-size)) * 1rem);
    letter-spacing: 0em;
  `,
  heroBody: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 400;
    font-size: calc((24 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.04em;
  `,
  heroBodyHeadline: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((24 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.04em;
  `,

  // Copy
  headline: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((32 / var(--base-font-size)) * 1rem);
    line-height: calc((38 / var(--base-font-size)) * 1rem);
    letter-spacing: 0em;
  `,
  subheadline: css`
    font-family: "Merriweather", -apple-system, sans-serif;
    font-weight: 500;
    font-size: calc((22 / var(--base-font-size)) * 1rem);
    line-height: calc((28 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;
  `,
  body: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 400;
    font-size: calc((16 / var(--base-font-size)) * 1rem);
    line-height: calc((28 / var(--base-font-size)) * 1rem);
    letter-spacing: 0em;
  `,
  bodyHeadline: css`
    font-family: "Merriweather", -apple-system, sans-serif;
    font-weight: 900;
    font-size: calc((16 / var(--base-font-size)) * 1rem);
    line-height: calc((20 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.04em;
  `,
  small: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 400;
    font-size: calc((14 / var(--base-font-size)) * 1rem);
    line-height: calc((20 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.02em;
  `,
  smallHeadline: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((14 / var(--base-font-size)) * 1rem);
    line-height: calc((20 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.02em;
  `,
  tiny: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((12 / var(--base-font-size)) * 1rem);
    line-height: calc((18 / var(--base-font-size)) * 1rem);
    letter-spacing: 0em;
  `,
  tinyHeadline: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((12 / var(--base-font-size)) * 1rem);
    line-height: calc((18 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.04em;
  `,

  // Buttons
  heroButton: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((22 / var(--base-font-size)) * 1rem);
    line-height: calc((47 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.04em;
  `,
  button: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((16 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.04em;
  `,
  buttonBold: css`
    font-family: "Roboto", -apple-system, sans-serif;
    font-weight: 700;
    font-size: calc((16 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.08em;
  `,

  // Card Options
  simple: css`
    font-family: "PalmerLakePrint-Regular", sans-serif;
    font-weight: normal;
    font-size: calc((48 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;
  `,
  bubble: css`
    font-family: "Sheepfill", serif;
    font-weight: normal;
    font-size: calc((24 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;
  `,
  teen: css`
    font-family: "catholicschoolgirls_intl_bbRg", serif;
    font-weight: normal;
    font-size: calc((24 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;
  `,
  childsPlay: css`
    font-family: "DO-Sans-RegularPRO", serif;
    font-weight: normal;
    font-size: calc((24 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;
  `,
  script: css`
    font-family: "BarbaraCalligraphy", serif;
    font-weight: normal;
    font-size: calc((24 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;
  `,
  punk: css`
    font-family: "MetalCoreNerve", serif;
    font-weight: normal;
    font-size: calc((34 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.05em;
  `,
  friendly: css`
    font-family: "PrettyAnimal", serif;
    font-weight: normal;
    font-size: calc((30 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;
  `,
  openSans: css`
    font-family: "Open Sans", -apple-system, sans-serif;
    font-weight: normal;
    font-size: calc((22 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;

    ${IS_SMALL} {
      font-size: calc((18 / var(--base-font-size)) * 1rem);
      line-height: calc((32 / var(--base-font-size)) * 1rem);
    }
  `,
  merriweather: css`
    font-family: "Merriweather", serif;
    font-weight: normal;
    font-size: calc((22 / var(--base-font-size)) * 1rem);
    line-height: calc((36 / var(--base-font-size)) * 1rem);
    letter-spacing: 0.01em;

    ${IS_SMALL} {
      font-size: calc((18 / var(--base-font-size)) * 1rem);
      line-height: calc((32 / var(--base-font-size)) * 1rem);
    }
  `,
};
